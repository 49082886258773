import app from "./app";
import {
  //
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  User,
  signOut,
} from "firebase/auth";

let _instance = null as Auth | null;

function _checkInstance() {
  if (!_instance) {
    _instance = getAuth(app);
  }
}
const obj = {
  get signinWithEmailAndPassword() {
    _checkInstance();
    return (
      props: { email: string; password: string },
      options?: { persist?: "browser_session" }
    ) => {
      let persist = undefined;
      if (options?.persist === "browser_session") {
        persist = browserSessionPersistence;
      }

      if (persist) {
        return setPersistence(_instance, persist).then(() => {
          return signInWithEmailAndPassword(
            _instance,
            props?.email,
            props?.password
          );
        });
      }
      return signInWithEmailAndPassword(
        _instance,
        props?.email,
        props?.password
      );
    };
  },
  get signOut() {
    _checkInstance();
    return () => {
      return signOut(_instance);
    };
  },
  get onAuthStateChanged() {
    _checkInstance();
    return (callback: (user: User) => void) => {
      return onAuthStateChanged(_instance, callback);
    };
  },
};

export default obj;
